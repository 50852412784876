import { RadioGroup } from "@headlessui/react"
import React, { Dispatch, SetStateAction, useEffect, useState } from "react"
import { useObjectivesContext } from "src/objectives/objectives.context"
import { capitalize, classNames, parseBoolean } from "utils"
import Spinner from "../../core/components/Spinner"
import { OBJECTIVE_PRIVACY_OPTIONS, PrivacyOption } from "../objectives.constants"
import { IObjectivePrivacyOption } from "../objectives.interfaces"

interface ObjectiveAddItemProps {
  name: string
}

interface IObjectivePrivacyOptionsProps {
  options: IObjectivePrivacyOption[]
  selected: IObjectivePrivacyOption
  setSelected: Dispatch<SetStateAction<IObjectivePrivacyOption>>
}

const ObjectivePrivacyOptions: React.FC<IObjectivePrivacyOptionsProps> = ({
  options,
  selected,
  setSelected,
}) => {
  return (
    <RadioGroup value={selected} onChange={setSelected} className="my-2 min-w-min">
      <div className="-space-y-px bg-white">
        {options.map((setting) => (
          <RadioGroup.Option
            key={setting.name}
            value={setting}
            className={({ checked }) =>
              classNames(
                checked ? "z-10 border-red-200" : "border-gray-200",
                "relative flex cursor-pointer p-2 focus:outline-none"
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span
                  className={classNames(
                    checked ? "border-transparent bg-red-600" : "border-gray-300 bg-white",
                    active ? "ring-2 ring-red-400 ring-offset-2" : "",
                    "mt-0.5 flex h-4 w-4 cursor-pointer items-center justify-center rounded-full border"
                  )}
                  aria-hidden="true"
                >
                  <span className="h-1.5 w-1.5 rounded-full bg-white" />
                </span>
                <div className="ml-3 flex flex-col">
                  <RadioGroup.Label
                    as="span"
                    className={classNames(
                      checked ? "text-red-700" : "text-gray-700",
                      "block text-sm font-medium"
                    )}
                  >
                    {setting.name}
                  </RadioGroup.Label>
                  <RadioGroup.Description
                    as="span"
                    className={classNames(
                      checked ? "text-red-700" : "text-gray-400",
                      "block text-sm"
                    )}
                  >
                    {setting.description}
                  </RadioGroup.Description>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  )
}

const ObjectiveAddItem: React.FC<ObjectiveAddItemProps> = ({ name }) => {
  const { isAdding, addObjective } = useObjectivesContext()

  const [selected, setSelected] = useState<IObjectivePrivacyOption>(
    OBJECTIVE_PRIVACY_OPTIONS.find((o) => o.key === PrivacyOption.PUBLIC)!
  )
  const [isPrivate, setIsPrivate] = useState<boolean>(false)

  useEffect(() => {
    setIsPrivate(selected?.key === PrivacyOption.PRIVATE)
  }, [selected])

  return (
    <div className="my-2 flex animate-fade-in-down">
      <div className="flex h-full w-full flex-col items-center border-none bg-white p-3 text-gray-400">
        <div className="inline-flex w-full">
          <div
            className={classNames(
              "w-full border-gray-200 bg-white px-2 py-2 text-center",
              parseBoolean(process.env.PRIVACY_ENABLED) ? "border-b" : ""
            )}
          >
            <h3 className="text-lg font-medium leading-6 text-gray-700">{capitalize(name)}</h3>
          </div>
        </div>
        {parseBoolean(process.env.PRIVACY_ENABLED) && (
          <ObjectivePrivacyOptions
            options={OBJECTIVE_PRIVACY_OPTIONS}
            selected={selected}
            setSelected={setSelected}
          />
        )}
        {!isAdding && (
          <button
            type="button"
            className="mt-2 flex w-24 items-center justify-center rounded-md border-transparent bg-red-400 px-4 py-2 text-center text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={async () => {
              await addObjective({
                name,
                isPrivate,
              })
            }}
          >
            Add
          </button>
        )}
        {isAdding && <Spinner color="red" />}
      </div>
    </div>
  )
}

export default ObjectiveAddItem
