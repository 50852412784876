import React, { ReactNode } from "react"
import useHover from "src/core/hooks/useHover"
import Spinner from "./Spinner"

interface HoverButtonProps {
  children: ReactNode
  hoverChildren: ReactNode
  action: () => void
  isLoading?: boolean
}

const HoverButton: React.FC<HoverButtonProps> = ({
  children,
  hoverChildren,
  action,
  isLoading,
}) => {
  const [hoverRef, isHovered] = useHover()

  return (
    <button
      type="button"
      onClick={(e) => {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
        action()
      }}
      className="flex items-center justify-center w-full h-full px-4 py-3 text-sm font-medium text-white bg-red-400 hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-red-500"
      disabled={isLoading}
      ref={hoverRef as React.MutableRefObject<HTMLButtonElement>}
    >
      {isLoading ? <Spinner /> : isHovered ? hoverChildren : children}
    </button>
  )
}

export default HoverButton
