import { useSession } from "@blitzjs/auth"
/* eslint-disable jsx-a11y/media-has-caption */
import { HandThumbUpIcon, MoonIcon, SunIcon } from "@heroicons/react/24/outline"
import { useEffect, useState } from "react"
import Tabs, { ITab } from "src/core/components/Tabs"
import ObjectivesList from "src/objectives/components/ObjectivesList"
import { capitalize } from "utils"
import SlideOver from "../../core/components/SlideOver"
import { useFocusSessionContext } from "../../focus-session/focus-session.context"
import ObjectiveNotes from "../../objective-notes/components/ObjectiveNotes"
import { ObjectiveStatus } from "../objectives.constants"
import { useObjectivesContext } from "../objectives.context"
import ObjectivesSearchbar from "./ObjectivesSearchbar"

const ObjectivesSection = () => {
  const {
    isViewOnly,
    isCurrentUser,
    userId,
    objectiveStatus,
    setObjectiveStatus,
    searchInput,
    noteObjective,
    setNoteObjective,
  } = useObjectivesContext()
  const { bellRef, focusedObjectiveId } = useFocusSessionContext()

  const canShowObjectivesSearchbar = () =>
    focusedObjectiveId === null && (isCurrentUser || (isViewOnly && !userId))

  const session = useSession({ suspense: false })

  const canShowObjectiveStatusTabs = () =>
    !!session && isCurrentUser && !focusedObjectiveId && !searchInput.length

  const { objectiveStatusCounts } = useObjectivesContext()

  const [tabs, setTabs] = useState<ITab[]>([
    {
      key: ObjectiveStatus.ACTIVE,
      value: capitalize(ObjectiveStatus.ACTIVE),
      icon: SunIcon,
    },
    {
      key: ObjectiveStatus.INACTIVE,
      value: capitalize(ObjectiveStatus.INACTIVE),
      icon: MoonIcon,
    },
    {
      key: ObjectiveStatus.SUGGESTED,
      value: capitalize(ObjectiveStatus.SUGGESTED),
      icon: HandThumbUpIcon as any,
    },
  ])

  useEffect(() => {
    Object.keys(objectiveStatusCounts).forEach((key) => {
      if (objectiveStatusCounts[key] !== undefined) {
        setTabs(
          tabs.map((tab) => {
            if (tab.key === key) {
              tab.count = objectiveStatusCounts[key]
            }
            return tab
          })
        )
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectiveStatusCounts])

  return (
    <>
      <div className="flex flex-col h-screen">
        {/* Full screen height and flex column */}
        <div className="py-4 flex">{canShowObjectivesSearchbar() && <ObjectivesSearchbar />}</div>
        {canShowObjectiveStatusTabs() && (
          <Tabs
            tabs={tabs}
            selected={objectiveStatus}
            onSelect={(tab: ITab) => {
              setObjectiveStatus(tab.key as ObjectiveStatus)
            }}
          />
        )}
        <div className="mt-1">
          <ObjectivesList />
        </div>
        <audio ref={bellRef} src={process.env.BELL_URL} />
      </div>
      <SlideOver
        title="Daily Journal"
        subtitle={noteObjective?.name}
        onDismiss={() => {
          setNoteObjective(undefined)
        }}
        isOpen={!!noteObjective}
      >
        {noteObjective && <ObjectiveNotes objective={noteObjective} />}
      </SlideOver>
    </>
  )
}

export default ObjectivesSection
