import classNames from "classnames"
import React, { forwardRef, HTMLAttributes, useEffect, useState } from "react"

import { useMutation } from "@blitzjs/rpc"
import { UniqueIdentifier } from "@dnd-kit/core"
import {
  ArrowUturnLeftIcon,
  HandThumbUpIcon,
  MoonIcon,
  PencilSquareIcon,
  StarIcon,
  SunIcon,
  UserIcon,
} from "@heroicons/react/24/outline"
import { HandThumbUpIcon as ThumbUpIconFilled } from "@heroicons/react/24/solid"
import { useRouter } from "next/router"
import { FaEllipsisVertical } from "react-icons/fa6"
import { SlMagicWand } from "react-icons/sl"
import { capitalize, parseBoolean } from "../../../../../../utils"
import {
  flattenTree,
  getChildrenByField,
  getRootNodeByChildId,
  TreeNode,
  updateNodeById,
} from "../../../../../../utils/tree"
import FocusSessionButton from "../../../../../focus-session/components/FocusSessionButton"
import FocusSessionDurationText from "../../../../../focus-session/components/FocusSessionDurationText"
import { useFocusSessionContext } from "../../../../../focus-session/focus-session.context"
import ObjectiveUserVotersButton from "../../../../../objective-users/components/ObjectiveUserVotersButton"
import createObjectiveUserVote from "../../../../../objective-users/mutations/createObjectiveUserVote"
import deleteObjectiveUserVote from "../../../../../objective-users/mutations/deleteObjectiveUserVote"
import ObjectiveAbandonDialog from "../../../../../objectives/components/ObjectiveAbandonDialog"
import { useObjectiveContext } from "../../../../../objectives/objective.context"
import { ObjectiveStatus } from "../../../../../objectives/objectives.constants"
import { useObjectivesContext } from "../../../../../objectives/objectives.context"
import IconButton from "../../../IconButton"
import ShortTimeAgo from "../../../ShortTimeAgo"
import Spinner from "../../../Spinner"
import ToggleIconButton from "../../../ToggleIconButton"
import { FlattenedItem } from "../../types"
import { removeItems } from "../../utilities"
import { Action } from "../Action"
import { Handle } from "../Handle"
import { Remove } from "../Remove"
import styles from "./TreeItem.module.css"

export interface Props extends Omit<HTMLAttributes<HTMLLIElement>, "id"> {
  id: UniqueIdentifier
  childCount?: number
  clone?: boolean
  collapsed?: boolean
  depth: number
  disableInteraction?: boolean
  disableSelection?: boolean
  ghost?: boolean
  handleProps?: any
  indicator?: boolean
  indentationWidth: number
  text: string
  onCollapse?(): void
  onRemove?(): void
  wrapperRef?(node: HTMLLIElement): void
  ActionNode?: any
  objective: any
  showOptions: boolean
  setShowOptions: (value: boolean) => void
}

export const TreeItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      id,
      childCount,
      clone,
      depth,
      disableSelection,
      disableInteraction,
      ghost,
      handleProps,
      indentationWidth,
      indicator,
      collapsed,
      onCollapse,
      onRemove,
      style,
      text,
      wrapperRef,
      ActionNode,
      objective,
      showOptions,
      setShowOptions,
      ...props
    },
    ref
  ) => {
    const router = useRouter()

    const {
      updateObjectiveAccessedAt,
      updateObjectiveIsActive,
      // updateObjectiveIsPrivate,
      adopt,
      abandon,
      isAdopting,
      isAbandoning,
    } = useObjectiveContext()

    const {
      isViewOnly,
      isCurrentUser,
      userId,
      onAdopt,
      onAbandon,
      onSetActive,
      objectiveStatus,
      searchInput,
      getSubObjectives,
      currentItems,
      goToObjective,
      isLoadingMap,
      setCurrentItems,
      setNoteObjective,
      update,
    } = useObjectivesContext()

    const { focusedObjectiveId, isFocusEndDialogOpen, setIsFocusEndDialogOpen } =
      useFocusSessionContext()

    // Adopt/abandon
    const [adopted, setAdopted] = useState<boolean>(!!objective.adoptedAt)
    const [isAbandonDialogOpen, setIsAbandonDialogOpen] = useState<boolean>(false)

    // Check for focus session
    useEffect(() => {
      const children: any[] = getChildrenByField(currentItems as TreeNode[], "focusRemainingSec")
      if (children.length === 0) return

      if (children[0]!.focusRemainingSec > 0) {
        const root: any = getRootNodeByChildId(
          currentItems as TreeNode[],
          children[0]!.id as number
        )
        if (!root) return
        // const items = updateNodeById(currentItems as any[], root.id, {
        //   collapsed: true,
        // })

        // update(items as FlattenedItem[])
        setShowOptions(true)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const startAdopt = async () => {
      if (isViewOnly) {
        await router.push("/login")
      } else {
        const userToObjective = await adopt(
          objective.id,
          objectiveStatus === ObjectiveStatus.SUGGESTED,
          objective.name,
          objective.parentId,
          !!objective.isActive || objectiveStatus === ObjectiveStatus.ACTIVE
        )

        if (userToObjective) {
          const data = {
            id: userToObjective.objective.id,
            adoptedAt: userToObjective.createdAt,
            accessedAt: userToObjective.createdAt,
            duration: 0,
            isActive: true,
            isPrivate: false,
          }
          const updatedItems = updateNodeById(
            currentItems as any[],
            objective.id,
            data
          ) as FlattenedItem[]
          objective = {
            ...objective,
            ...data,
          }

          setCurrentItems((prev) => [...updatedItems])

          await onAdopt()
        }
        setAdopted(true)
      }
    }

    const startAbandon = async () => {
      if (isViewOnly) {
        await router.push("/login")
      } else {
        setIsAbandonDialogOpen(true)
      }
    }

    const canShowTimeAgo = () =>
      !isViewOnly && (objective.adoptedAt || !isCurrentUser) && focusedObjectiveId !== objective.id

    const canShowObjectiveUserCount = () =>
      !objective.adoptedAt && isCurrentUser && !objective.voteCount

    const canShowFocusSessionButton = () =>
      parseBoolean(process.env.FOCUS_ENABLED) &&
      isCurrentUser &&
      objective.isActive &&
      objective.adoptedAt

    const canShowFocusDurationText = () =>
      parseBoolean(process.env.FOCUS_ENABLED) &&
      objective.adoptedAt &&
      focusedObjectiveId === objective.id

    const canShowSetActiveButton = () =>
      focusedObjectiveId !== objective.id && objective.adoptedAt && isCurrentUser

    const canShowJournalButton = () =>
      isCurrentUser && objective.adoptedAt && focusedObjectiveId !== objective.id

    const canShowOptions = () => !clone && adopted && isCurrentUser && !showOptions

    const canShowRemove = () =>
      !adopted && isCurrentUser && objectiveStatus !== ObjectiveStatus.SUGGESTED && !searchInput

    const [isVoted, setIsVoted] = useState<boolean>(!!objective.voteCount)
    const [invokeVote, { isLoading: isVoting }] = useMutation(createObjectiveUserVote)
    const [invokeUnvote, { isLoading: isUnvoting }] = useMutation(deleteObjectiveUserVote)

    const vote = async (userId: number) => {
      await invokeVote({
        userId,
        objectiveId: objective.id,
      })
      setIsVoted(true)
    }

    const unvote = async (userId: number) => {
      await invokeUnvote({
        userId,
        objectiveId: objective.id,
      })
      setIsVoted(false)
    }

    const canShowVoteButton = () =>
      !!userId && !isCurrentUser && !isViewOnly && parseBoolean(process.env.OBJECTIVE_VOTES_ENABLED)

    const canShowGenerateButton = () =>
      isCurrentUser &&
      !isViewOnly &&
      parseBoolean(process.env.OBJECTIVE_GENERATE_ENABLED) &&
      focusedObjectiveId !== objective.id

    useEffect(() => {
      setAdopted(!!objective.adoptedAt)
    }, [objective.adoptedAt])

    useEffect(() => {
      if (!!!showOptions) {
        setCurrentItems((items) => {
          const ids = objective.children
            .filter((o) => !(o as any).adoptedAt)
            .map((o) => o.id as number)
          const newItems = removeItems(items, ids)
          return newItems as FlattenedItem[]
        })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showOptions])

    return (
      <>
        <li
          className={classNames(
            styles.Wrapper,
            clone && styles.clone,
            ghost && styles.ghost,
            indicator && styles.indicator,
            disableSelection && styles.disableSelection,
            disableInteraction && styles.disableInteraction,
            !!focusedObjectiveId && focusedObjectiveId !== objective.id ? "hidden" : ""
          )}
          ref={wrapperRef}
          style={
            {
              "--spacing": `${
                focusedObjectiveId !== objective.id ? indentationWidth * depth : 0
              }px`,
            } as React.CSSProperties
          }
          onClick={(e) => {
            e.stopPropagation()
            e.nativeEvent.stopImmediatePropagation()

            if (adopted) {
              goToObjective(objective.id as number)
              updateObjectiveAccessedAt(objective.id).catch(console.error)
            }
          }}
          {...props}
        >
          <div
            className={`min-h-12 my-1 flex relative cursor-pointer bg-white shadow-sm  ${
              adopted ? "hover:bg-gray-200" : ""
            }`}
            ref={ref}
            style={style}
          >
            {!clone && (!showOptions || searchInput) && (
              <>
                <ToggleIconButton
                  isToggled={adopted}
                  onChildren={
                    <StarIcon className="h-5 w-5 fill-current stroke-current text-gray-400" />
                  }
                  offChildren={<StarIcon className="h-5 w-5 stroke-current text-gray-400" />}
                  hoverAndOnChildren={<StarIcon className="h-5 w-5 stroke-current text-gray-400" />}
                  hoverAndOffChildren={
                    <StarIcon className="h-5 w-5 fill-current stroke-current text-gray-400" />
                  }
                  isLoading={isAbandoning || isAdopting}
                  toggleOn={async () => {
                    await startAdopt()
                  }}
                  toggleOff={async () => {
                    await startAbandon()
                  }}
                  title={adopted ? "Unfollow" : "Follow"}
                />
              </>
            )}

            {showOptions && !searchInput && focusedObjectiveId !== objective.id && (
              <Handle {...handleProps} className="mx-1" />
            )}

            {onCollapse && focusedObjectiveId !== objective.id && (
              <Action
                onClick={(e) => {
                  e.stopPropagation()
                  e.nativeEvent.stopImmediatePropagation()
                  onCollapse()
                }}
                className={classNames(styles.Collapse, collapsed && styles.collapsed)}
                title={collapsed ? "Expand" : "Collapse"}
              >
                {collapseIcon}
              </Action>
            )}

            <div className="flex flex-1 items-center justify-between">
              <span
                className={classNames(
                  "text-md flex-1 py-3 pr-4 font-medium text-gray-500 break-anywhere",
                  isViewOnly ? "pl-4" : "",
                  focusedObjectiveId === objective.id ? "pl-4" : "",
                  clone ? "pl-4" : ""
                )}
                style={{ overflowWrap: "anywhere" }}
              >
                {capitalize(text)}
              </span>
              {!showOptions && !clone && (
                <div className="inline-flex flex-shrink items-center px-3 text-sm text-gray-500">
                  {canShowTimeAgo() && objective.accessedAt && (
                    <ShortTimeAgo date={objective.accessedAt} />
                  )}

                  {canShowFocusDurationText() && <FocusSessionDurationText />}

                  {canShowObjectiveUserCount() && (
                    <div className="inline-flex items-center">
                      <UserIcon className="text-grey-100 h-5 w-5" />
                      <div>{objective.userCount || 0}</div>
                    </div>
                  )}

                  {objective.voteCount !== undefined && isCurrentUser && (
                    <ObjectiveUserVotersButton
                      objectiveId={objective.id}
                      voteCount={objective.voteCount}
                    />
                  )}
                </div>
              )}
            </div>

            {ActionNode && <ActionNode id={id} />}

            {!clone && (
              <div
                className={classNames(
                  showOptions ? " absolute right-0 bg-white mr-0 h-full" : "flex"
                )}
              >
                {showOptions && adopted && (
                  <div className="flex h-full">
                    {canShowFocusSessionButton() && (
                      <div className="flex h-full">
                        <FocusSessionButton
                          objective={objective}
                          className="text-gray-400 hover:bg-gray-300 hover:text-gray-500 h-full"
                        />
                      </div>
                    )}

                    {canShowJournalButton() && (
                      <IconButton
                        icon={<PencilSquareIcon className="h-5 w-5 stroke-current text-gray-400" />}
                        action={async () => {
                          setNoteObjective(objective)
                        }}
                        isLoading={false}
                        className="text-gray-400 hover:bg-gray-300 hover:text-gray-500"
                        title="Journal"
                      />
                    )}

                    {canShowSetActiveButton() && (
                      <div className="flex-shrink-0">
                        <IconButton
                          icon={
                            objective.isActive ? (
                              <MoonIcon className="h-5 w-5" aria-hidden="true" />
                            ) : (
                              <SunIcon className="h-5 w-5" aria-hidden="true" />
                            )
                          }
                          action={async () => {
                            const ids = flattenTree(objective).map((o) => o.id as number)
                            await updateObjectiveIsActive(
                              [objective?.id!, ...ids],
                              !objective.isActive
                            )
                            onSetActive(objective.id, !objective.isActive)
                          }}
                          isLoading={false}
                          className="text-gray-400 hover:bg-gray-300 hover:text-gray-500"
                          title={objective.isActive ? "Archive" : "Follow now"}
                        />
                      </div>
                    )}

                    {
                      // Generate children
                      canShowGenerateButton() && (
                        <Action
                          onClick={(e) => {
                            if (isLoadingMap[id]) {
                              return
                            }
                            e.stopPropagation()
                            e.nativeEvent.stopImmediatePropagation()
                            getSubObjectives({
                              id,
                              name: text,
                              children: objective.children,
                            } as any)
                          }}
                          className={classNames(
                            "text-gray-400 hover:bg-gray-300 hover:text-gray-500 px-3 py-3"
                          )}
                          title="Generate sub-objectives"
                        >
                          {isLoadingMap[id] ? (
                            <Spinner size={5} />
                          ) : (
                            <SlMagicWand className="w-4 h-4" aria-hidden="true" />
                          )}
                        </Action>
                      )
                    }

                    {focusedObjectiveId !== objective.id && (
                      <IconButton
                        icon={
                          <ArrowUturnLeftIcon className="h-5 w-5 stroke-current text-gray-400" />
                        }
                        action={async () => {
                          setShowOptions(false)
                        }}
                        isLoading={false}
                        className="text-gray-400 hover:bg-gray-300 hover:text-gray-500"
                        title="Back"
                      />
                    )}
                  </div>
                )}
                {canShowOptions() && (
                  <IconButton
                    icon={<FaEllipsisVertical className="h-5 w-5 stroke-current text-gray-400" />}
                    action={async () => {
                      setShowOptions(true)
                    }}
                    isLoading={false}
                    className="text-gray-400 hover:bg-gray-300 hover:text-gray-500"
                    title="Back"
                  />
                )}

                {canShowRemove() && onRemove && (
                  <Remove
                    onClick={(event) => {
                      event.stopPropagation()
                      event.nativeEvent.stopImmediatePropagation()
                      onRemove()
                    }}
                  />
                )}
              </div>
            )}

            {
              // Vote button
              !!userId && canShowVoteButton() && (
                <ToggleIconButton
                  isToggled={isVoted}
                  onChildren={
                    <ThumbUpIconFilled className="h-5 w-5 stroke-current text-gray-400" />
                  }
                  offChildren={<HandThumbUpIcon className="h-5 w-5 stroke-current text-gray-400" />}
                  hoverAndOnChildren={
                    <HandThumbUpIcon className="h-5 w-5 stroke-current text-gray-400" />
                  }
                  hoverAndOffChildren={
                    <ThumbUpIconFilled className="h-5 w-5 stroke-current text-gray-400" />
                  }
                  isLoading={isVoting || isUnvoting}
                  toggleOn={async () => {
                    await vote(userId)
                  }}
                  toggleOff={async () => {
                    await unvote(userId)
                  }}
                />
              )
            }

            {clone && childCount && childCount > 1 ? (
              <span className={styles.Count}>{childCount}</span>
            ) : null}
          </div>
        </li>
        {isAbandonDialogOpen && (
          <ObjectiveAbandonDialog
            title={objective.name}
            isOpen={isAbandonDialogOpen}
            setIsOpen={setIsAbandonDialogOpen}
            abandon={async () => {
              const ids = flattenTree(objective).map((o) => o.id)
              if (await abandon([objective?.id!, ...ids])) {
                await onAbandon(objective?.id!)
              }
            }}
          />
        )}
      </>
    )
  }
)

const collapseIcon = (
  <svg width="10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 41">
    <path d="M30.76 39.2402C31.885 40.3638 33.41 40.995 35 40.995C36.59 40.995 38.115 40.3638 39.24 39.2402L68.24 10.2402C69.2998 9.10284 69.8768 7.59846 69.8494 6.04406C69.822 4.48965 69.1923 3.00657 68.093 1.90726C66.9937 0.807959 65.5106 0.178263 63.9562 0.150837C62.4018 0.123411 60.8974 0.700397 59.76 1.76024L35 26.5102L10.24 1.76024C9.10259 0.700397 7.59822 0.123411 6.04381 0.150837C4.4894 0.178263 3.00632 0.807959 1.90702 1.90726C0.807714 3.00657 0.178019 4.48965 0.150593 6.04406C0.123167 7.59846 0.700153 9.10284 1.75999 10.2402L30.76 39.2402Z" />
  </svg>
)
