import React from "react"
import { useFocusSessionContext } from "../focus-session.context"

const FocusSessionDurationText = () => {
  const { focusDuration } = useFocusSessionContext()

  return <>{Math.floor(focusDuration / 60)}h</>
}

export default FocusSessionDurationText
