import { useQuery } from "@blitzjs/rpc"
import { Popover, Transition } from "@headlessui/react"
import React, { Fragment } from "react"
import UserItem from "src/users/components/UserItem"
import UsersListSkeleton from "src/users/components/UsersListSkeleton"
import getObjectiveUserVoters from "../queries/getObjectiveUserVoters"

interface Props {
  objectiveId: number
  voteCount: number
}

const ObjectiveUserVotersPopover = ({ objectiveId, voteCount }: Props) => {
  const [voters, { isLoading }] = useQuery(
    getObjectiveUserVoters,
    { objectiveId },
    {
      suspense: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  )

  return (
    <Transition
      as={Fragment}
      enter="transition ease-out duration-200"
      enterFrom="opacity-0 translate-y-1"
      enterTo="opacity-100 translate-y-0"
      leave="transition ease-in duration-150"
      leaveFrom="opacity-100 translate-y-0"
      leaveTo="opacity-0 translate-y-1"
    >
      <Popover.Panel className="absolute z-10 w-screen max-w-sm -translate-x-full bg-gray-100 shadow-md left-full">
        <div className="px-4 py-2 text-left">Suggested by</div>
        {isLoading ? (
          <UsersListSkeleton size={voteCount} />
        ) : (
          voters?.map((voter) => <UserItem key={voter.id} user={voter} noFollow={true} />)
        )}
      </Popover.Panel>
    </Transition>
  )
}

export default ObjectiveUserVotersPopover
