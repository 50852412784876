import {
  Announcements,
  DndContext,
  DragEndEvent,
  DragMoveEvent,
  DragOverEvent,
  DragOverlay,
  DragStartEvent,
  DropAnimation,
  MeasuringStrategy,
  Modifier,
  PointerSensor,
  UniqueIdentifier,
  closestCenter,
  defaultDropAnimation,
  useSensor,
  useSensors,
} from "@dnd-kit/core"
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable"
import { CSSProperties, useEffect, useMemo, useRef, useState } from "react"
import { createPortal } from "react-dom"

import { CSS } from "@dnd-kit/utilities"
import { findNode } from "../../../../utils/tree"
import { useFocusSessionContext } from "../../../focus-session/focus-session.context"
import { ObjectiveProvider } from "../../../objectives/objective.context"
import { useObjectivesContext } from "../../../objectives/objectives.context"
import DialogBox from "../DialogBox"
import { SortableTreeItem } from "./components"
import type { FlattenedItem, ITreeItem, SensorContext } from "./types"
import {
  buildTree,
  flattenTree,
  getChildCount,
  getProjection,
  removeChildrenOf,
  removeItem,
  setProperty,
} from "./utilities"

const initialItems: ITreeItem[] = []

const measuring = {
  droppable: {
    strategy: MeasuringStrategy.Always,
  },
}

const dropAnimationConfig: DropAnimation = {
  keyframes({ transform }) {
    return [
      { opacity: 1, transform: CSS.Transform.toString(transform.initial) },
      {
        opacity: 0,
        transform: CSS.Transform.toString({
          ...transform.final,
          x: transform.final.x + 5,
          y: transform.final.y + 5,
        }),
      },
    ]
  },
  easing: "ease-out",
  sideEffects({ active }) {
    active.node.animate([{ opacity: 0 }, { opacity: 1 }], {
      duration: defaultDropAnimation.duration,
      easing: defaultDropAnimation.easing,
    })
  },
}

interface Props {
  collapsible?: boolean
  defaultItems?: ITreeItem[]
  indentationWidth?: number
  indicator?: boolean
  removable?: boolean
  itemStyle?: CSSProperties
  actionNode?: JSX.Element
  onOrderChange: (items: ITreeItem[]) => void
}

export function NestedSortable({
  collapsible,
  defaultItems = initialItems,
  indicator = false,
  indentationWidth = 40,
  removable,
  onOrderChange,
  itemStyle = {},
  actionNode,
}: Props) {
  const [items, setItems] = useState(() => defaultItems)
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null)
  const [overId, setOverId] = useState<UniqueIdentifier | null>(null)
  const [offsetLeft, setOffsetLeft] = useState(0)
  const [currentPosition, setCurrentPosition] = useState<{
    parentId: UniqueIdentifier | null
    overId: UniqueIdentifier
  } | null>(null)

  const flattenedItems = useMemo(() => {
    const flattenedTree = flattenTree(items)
    const collapsedItems = flattenedTree.reduce<UniqueIdentifier[]>(
      (acc, { children, collapsed, id }) => (collapsed && children.length ? [...acc, id] : acc),
      []
    )

    return removeChildrenOf(
      flattenedTree,
      activeId ? [activeId, ...collapsedItems] : collapsedItems
    )
  }, [activeId, items])
  const projected =
    activeId && overId
      ? getProjection(flattenedItems, activeId, overId, offsetLeft, indentationWidth)
      : null
  const sensorContext: SensorContext = useRef({
    items: flattenedItems,
    offset: offsetLeft,
  })

  const sensors = useSensors(useSensor(PointerSensor))

  const sortedIds = useMemo(() => flattenedItems.map(({ id }) => id), [flattenedItems])
  const activeItem = activeId ? flattenedItems.find(({ id }) => id === activeId) : null

  useEffect(() => {
    sensorContext.current = {
      items: flattenedItems,
      offset: offsetLeft,
    }
  }, [flattenedItems, offsetLeft])

  const announcements: Announcements = {
    onDragStart({ active }) {
      return `Picked up ${active.id}.`
    },
    onDragMove({ active, over }) {
      return getMovementAnnouncement("onDragMove", active.id, over?.id)
    },
    onDragOver({ active, over }) {
      return getMovementAnnouncement("onDragOver", active.id, over?.id)
    },
    onDragEnd({ active, over }) {
      return getMovementAnnouncement("onDragEnd", active.id, over?.id)
    },
    onDragCancel({ active }) {
      return `Moving was cancelled. ${active.id} was dropped in its original position.`
    },
  }

  useEffect(() => {
    setItems(defaultItems)
  }, [defaultItems])

  const { lastFocusedObjectiveId, isFocusEndDialogOpen, setIsFocusEndDialogOpen } =
    useFocusSessionContext()

  const { setNoteObjective } = useObjectivesContext()

  const [currentShowOptionsId, setCurrentShowOptionsId] = useState<UniqueIdentifier | null>(null)

  return (
    <DndContext
      accessibility={{ announcements }}
      sensors={sensors}
      collisionDetection={closestCenter}
      measuring={measuring}
      onDragStart={handleDragStart}
      onDragMove={handleDragMove}
      onDragOver={handleDragOver}
      onDragEnd={handleDragEnd}
      onDragCancel={handleDragCancel}
    >
      <SortableContext items={sortedIds} strategy={verticalListSortingStrategy}>
        {flattenedItems.map((objective) => {
          const { id, name, children, collapsed, depth, parentId, ...props } = objective
          return (
            <ObjectiveProvider
              key={(objective.parentId ? objective.parentId + "." : "") + objective.id}
              id={objective.id as number}
              objective={{
                ...objective,
                id: objective.id as number,
                children: objective.children as any,
              }}
            >
              <SortableTreeItem
                key={(parentId ? parentId + "." : "") + id}
                id={id}
                text={name}
                depth={id === activeId && projected ? projected.depth : depth}
                indentationWidth={indentationWidth}
                indicator={indicator}
                collapsed={objective.collapsed}
                onCollapse={collapsible && children.length ? () => handleCollapse(id) : undefined}
                onRemove={
                  removable
                    ? () => {
                        handleRemove(id)
                      }
                    : undefined
                }
                itemStyle={itemStyle}
                actionNode={actionNode}
                objective={objective}
                showOptions={currentShowOptionsId === id}
                setShowOptions={(value) => {
                  setCurrentShowOptionsId(value ? id : null)
                }}
              />
            </ObjectiveProvider>
          )
        })}
        {createPortal(
          <DragOverlay
            dropAnimation={dropAnimationConfig}
            modifiers={indicator ? [adjustTranslate] : undefined}
          >
            {activeId && activeItem ? (
              <ObjectiveProvider
                key={(activeItem.parentId ? activeItem.parentId + "." : "") + activeId}
                id={activeId as number}
                objective={{
                  ...activeItem,
                  id: activeItem.id as number,
                  children: activeItem.children as any,
                }}
              >
                <SortableTreeItem
                  id={(activeItem.parentId ? activeItem.parentId + "." : "") + activeId}
                  depth={activeItem.depth}
                  clone
                  childCount={getChildCount(items, activeId) + 1}
                  text={activeItem.name}
                  indentationWidth={indentationWidth}
                  itemStyle={itemStyle}
                  actionNode={actionNode}
                  objective={activeItem}
                  showOptions={currentShowOptionsId === activeId}
                  setShowOptions={(value) => {
                    setCurrentShowOptionsId(value ? activeId : null)
                  }}
                />
              </ObjectiveProvider>
            ) : null}
          </DragOverlay>,
          document.body
        )}
      </SortableContext>
      <DialogBox
        title="Time's up!"
        description="Want to add or update today's journal entry?"
        isOpen={isFocusEndDialogOpen}
        setIsOpen={setIsFocusEndDialogOpen}
        onConfirm={() => {
          const focusedObjective = findNode(items as any[], lastFocusedObjectiveId as number)
          setNoteObjective(focusedObjective as any)
        }}
        cancelText="No"
        confirmText="Yes"
      />
    </DndContext>
  )

  function handleDragStart({ active: { id: activeId } }: DragStartEvent) {
    setActiveId(activeId)
    setOverId(activeId)

    const activeItem = flattenedItems.find(({ id }) => id === activeId)

    if (activeItem) {
      setCurrentPosition({
        parentId: activeItem.parentId,
        overId: activeId,
      })
    }

    document.body.style.setProperty("cursor", "grabbing")
  }

  function handleDragMove({ delta }: DragMoveEvent) {
    setOffsetLeft(delta.x)
  }

  function handleDragOver({ over }: DragOverEvent) {
    setOverId(over?.id ?? null)
  }

  function handleDragEnd({ active, over }: DragEndEvent) {
    resetState()

    if (projected && over) {
      const { depth, parentId } = projected
      const clonedItems: FlattenedItem[] = JSON.parse(JSON.stringify(flattenTree(items)))
      const overIndex = clonedItems.findIndex(({ id }) => id === over.id)
      const activeIndex = clonedItems.findIndex(({ id }) => id === active.id)
      const activeTreeItem = clonedItems[activeIndex]

      clonedItems[activeIndex] = { ...activeTreeItem, depth, parentId } as any

      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex)
      const newItems = buildTree(sortedItems)

      onOrderChange(newItems)
      setItems(newItems)
    }
  }

  function handleDragCancel() {
    resetState()
  }

  function resetState() {
    setOverId(null)
    setActiveId(null)
    setOffsetLeft(0)
    setCurrentPosition(null)

    document.body.style.setProperty("cursor", "")
  }

  function handleRemove(id: UniqueIdentifier) {
    setItems((items) => {
      const newItems = removeItem(items, id)
      onOrderChange(newItems)
      return newItems
    })
  }

  function handleCollapse(id: UniqueIdentifier) {
    setItems((items) =>
      setProperty(items, id, "collapsed", (value) => {
        return !value
      })
    )
  }

  function getMovementAnnouncement(
    eventName: string,
    activeId: UniqueIdentifier,
    overId?: UniqueIdentifier
  ) {
    if (overId && projected) {
      if (eventName !== "onDragEnd") {
        if (
          currentPosition &&
          projected.parentId === currentPosition.parentId &&
          overId === currentPosition.overId
        ) {
          return
        } else {
          setCurrentPosition({
            parentId: projected.parentId,
            overId,
          })
        }
      }

      const clonedItems: FlattenedItem[] = JSON.parse(JSON.stringify(flattenTree(items)))
      const overIndex = clonedItems.findIndex(({ id }) => id === overId)
      const activeIndex = clonedItems.findIndex(({ id }) => id === activeId)
      const sortedItems = arrayMove(clonedItems, activeIndex, overIndex)

      const previousItem = sortedItems[overIndex - 1]

      let announcement
      const movedVerb = eventName === "onDragEnd" ? "dropped" : "moved"
      const nestedVerb = eventName === "onDragEnd" ? "dropped" : "nested"

      if (!previousItem) {
        const nextItem = sortedItems[overIndex + 1]
        announcement = `${activeId} was ${movedVerb} before ${nextItem!.id}.`
      } else {
        if (projected.depth > previousItem.depth) {
          announcement = `${activeId} was ${nestedVerb} under ${previousItem.id}.`
        } else {
          let previousSibling: FlattenedItem | undefined = previousItem
          while (previousSibling && projected.depth < previousSibling.depth) {
            const parentId: UniqueIdentifier | null = previousSibling.parentId
            previousSibling = sortedItems.find(({ id }) => id === parentId)
          }

          if (previousSibling) {
            announcement = `${activeId} was ${movedVerb} after ${previousSibling.id}.`
          }
        }
      }

      return announcement
    }

    return
  }
}

const adjustTranslate: Modifier = ({ transform }) => {
  return {
    ...transform,
    y: transform.y - 25,
  }
}
