import { getAntiCSRFToken } from "@blitzjs/auth"

const startFocusSession = async (objectiveId: number, duration: number) => {
  const antiCSRFToken = getAntiCSRFToken()
  const response = await fetch("/api/focus", {
    method: "POST",
    credentials: "include",
    headers: {
      "anti-csrf": antiCSRFToken,
    },
    body: JSON.stringify({
      objectiveId,
      duration,
    }),
  })
  return await response.json()
}

const stopFocusSession = async (objectiveId: string | number | null) => {
  const antiCSRFToken = getAntiCSRFToken()
  const response = await fetch("/api/focus", {
    method: "DELETE",
    credentials: "include",
    headers: {
      "anti-csrf": antiCSRFToken,
    },
    body: JSON.stringify({
      ...(objectiveId && { objectiveId }),
    }),
  })
  return await response.status
}

const FocusSessionsService = {
  startFocusSession,
  stopFocusSession,
}

export default FocusSessionsService
