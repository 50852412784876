import { MagnifyingGlassIcon } from "@heroicons/react/24/outline"
import { useObjectivesContext } from "src/objectives/objectives.context"

const ObjectivesSearchbar = () => {
  const { searchInput, setSearchInput } = useObjectivesContext()

  return (
    <div className="w-full shadow-sm">
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <div className="relative text-red-600 focus-within:text-gray-400">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <MagnifyingGlassIcon className="flex-shrink-0 w-5 h-5" aria-hidden="true" />
        </div>
        <input
          id="search"
          name="search"
          className="block w-full py-4 pl-10 pr-3 text-lg leading-6 placeholder-gray-400 bg-white border border-transparent focus:shadow-xl rounded-md focus:outline-none  focus:ring-gray-200 focus:border-gray-200 focus:placeholder-gray-500 focus:text-gray-900 "
          placeholder="What would you like to do?"
          type="search"
          value={searchInput}
          onChange={(event) => setSearchInput(event.target.value)}
        />
      </div>
    </div>
  )
}

export default ObjectivesSearchbar
