import constate from "constate"
import { useCallback, useEffect, useState } from "react"
import useCountDown from "react-countdown-hook"
import useAudio from "src/core/hooks/useAudio"
import { FocusSessionDuration } from "./focus-session.constants"
import FocusSessionsService from "./focus-session.service"

const useFocusSession = () => {
  const [focusedObjectiveId, setFocusedObjectiveId] = useState<number | string | null>(null)
  const [lastFocusedObjectiveId, setLastFocusedObjectiveId] = useState<number | string | null>(null)
  const { audioRef: bellRef, play: playBell } = useAudio()
  const [timeLeft, { start: startTimer, pause, reset }] = useCountDown(
    FocusSessionDuration.SHORT * 60 * 1000
  )
  const [displayedTimeLeft, setDisplayedTimeLeft] = useState<number>()
  const [focusDuration, setFocusDuration] = useState<number>(0)
  const [isFocusEndDialogOpen, setIsFocusEndDialogOpen] = useState<boolean>(false)

  const stopFocus = useCallback(async () => {
    try {
      await FocusSessionsService.stopFocusSession(focusedObjectiveId)
      setFocusDuration(focusDuration)
      setFocusedObjectiveId(null)
      setLastFocusedObjectiveId(focusedObjectiveId)
      pause()
      reset()
    } catch (e) {
      // Handle error
    }
  }, [focusDuration, pause, reset, focusedObjectiveId])

  const startFocus = useCallback(
    async (id: number, duration: number) => {
      try {
        await FocusSessionsService.startFocusSession(id, duration)
        setFocusedObjectiveId(id)
        startTimer(duration * 60 * 1000)
      } catch (e) {
        // Handle error
      }
    },
    [startTimer]
  )

  useEffect(() => {
    if (timeLeft >= 0) {
      const rounded = Math.ceil(timeLeft / (60 * 1000))
      if (rounded <= 0 && focusedObjectiveId !== null) {
        stopFocus().catch((_err) => {})
        playBell().catch((_err) => {
          console.error(_err)
        })
        setIsFocusEndDialogOpen(true)
      } else {
        setDisplayedTimeLeft(rounded)
      }
    }
  }, [focusedObjectiveId, playBell, stopFocus, timeLeft])

  return {
    bellRef,
    focusedObjectiveId,
    setFocusedObjectiveId,
    startFocus,
    stopFocus,
    focusDuration,
    setFocusDuration,
    startTimer,
    displayedTimeLeft,
    isFocusEndDialogOpen,
    setIsFocusEndDialogOpen,
    lastFocusedObjectiveId,
  }
}

export const [FocusSessionProvider, useFocusSessionContext] = constate(useFocusSession)
