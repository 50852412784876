import React, { ReactNode } from "react"
import { classNames } from "utils"

interface IconButtonProps {
  icon: ReactNode
  isLoading: boolean
  action: () => Promise<void>
  className?: string
  title?: string
}

const IconButton: React.FC<IconButtonProps> = ({ icon, action, isLoading, className, title }) => {
  return (
    <button
      type="button"
      onClick={async (e) => {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
        await action()
      }}
      className={classNames(
        "flex items-center justify-center w-full h-full px-3 py-3 text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-300 focus:ring-gray-500",
        className
      )}
      disabled={isLoading}
      title={title}
    >
      {icon}
    </button>
  )
}

export default IconButton
