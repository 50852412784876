import { useCallback, useRef } from "react"

const useAudio = () => {
  const audioRef = useRef<HTMLAudioElement>(null)

  const stop = useCallback(async () => {
    if (audioRef.current) {
      await audioRef.current.pause()
      audioRef.current.currentTime = 0.0
    }
  }, [audioRef])

  const play = useCallback(async () => {
    if (audioRef.current) {
      await stop()
      await audioRef.current.play()
    }
  }, [audioRef, stop])

  return {
    audioRef,
    play,
  }
}

export default useAudio
