import { useMutation, useQuery } from "@blitzjs/rpc"
import { useEffect, useState } from "react"
import TimeAgo from "react-timeago"
import ErrorRetry from "src/core/components/ErrorRetry"
import Spinner from "src/core/components/Spinner"
import deleteObjectiveNote from "../mutations/deleteObjectiveNote"
import upsertObjectiveNote from "../mutations/upsertObjectiveNote"
import { IObjectiveNote } from "../objective-notes.interface"
import getObjectiveNote from "../queries/getObjectiveNote"

interface ObjectiveNoteEditProps {
  objectiveNoteId?: number
  objectiveId: number
  onDismiss: () => void
  onSave: ({ id, createdAt, updatedAt }: IObjectiveNote) => void
  onDelete: (id: number) => void
  date?: Date
}

const ObjectiveNoteEdit = ({
  objectiveNoteId,
  objectiveId,
  onDismiss,
  onSave,
  onDelete,
  date,
}: ObjectiveNoteEditProps) => {
  const [objectiveNote, { isFetching, isError, isSuccess, refetch }] = useQuery(
    getObjectiveNote,
    { id: objectiveNoteId },
    {
      enabled: false,
      suspense: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  )

  useEffect(() => {
    if (objectiveNoteId && objectiveNoteId > 0) {
      refetch().catch((_err) => {})
    }
  }, [objectiveNoteId, refetch])

  const [invokeSave, { isLoading: isSaving }] = useMutation(upsertObjectiveNote)
  const [invokeDelete, { isLoading: isDeleting }] = useMutation(deleteObjectiveNote)

  const [content, setContent] = useState<string>(objectiveNote?.content || "")

  useEffect(() => {
    if (objectiveNote) {
      setContent(objectiveNote.content)
    }
  }, [objectiveNote])

  const [displayDate, setDisplayDate] = useState<string>("Today")

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0]
    if (objectiveNote) {
      if (objectiveNote.createdAt.toISOString().split("T")[0] === today) {
        setDisplayDate("Today")
      } else {
        setDisplayDate(
          objectiveNote.createdAt.toLocaleDateString("en-US", {
            weekday: "long",
            month: "short",
            day: "numeric",
          })
        )
      }
    } else if (date && date.toISOString().split("T")[0] !== today) {
      setDisplayDate(
        date.toLocaleDateString("en-US", {
          weekday: "long",
          month: "short",
          day: "numeric",
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [objectiveNote])

  return (
    <>
      {(isSuccess || !isFetching) && (
        <div>
          <div className="text-lg text-gray-700">{displayDate}</div>
          <div className="mt-2">
            <textarea
              title="content"
              id="content"
              name="content"
              rows={12}
              required
              className="block w-full p-2 text-gray-600 border-gray-300 rounded-md shadow-sm focus:ring-red-500 focus:border-red-500 sm:text-md"
              value={content}
              onChange={(e) => {
                setContent(e.target.value)
              }}
            />
          </div>
          {objectiveNote && (
            <div className="flex justify-end mt-2">
              <p className="text-sm text-gray-500">
                Last updated: <TimeAgo date={objectiveNote.updatedAt} live={false} />
              </p>
            </div>
          )}
          <div className="pt-5">
            {isFetching ? (
              <div className="flex justify-end">
                <Spinner size={8} />
              </div>
            ) : (
              <div className="flex flex-row-reverse justify-between">
                <div className="inline-flex">
                  <button
                    type="button"
                    className="px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    onClick={onDismiss}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    disabled={isDeleting || isSaving}
                    className="inline-flex items-center justify-center px-4 py-2 ml-3 text-sm font-medium text-white bg-red-600 border border-transparent rounded-md shadow-sm disabled:opacity-50 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    onClick={async () => {
                      try {
                        if (content.length) {
                          const result = await invokeSave({
                            content,
                            id: objectiveNote?.id,
                            objectiveId,
                            date,
                          })
                          if (result) {
                            onSave(result)
                          }
                        } else if (objectiveNote) {
                          try {
                            await invokeDelete({ id: objectiveNote.id })
                            onDelete(objectiveNote.id)
                            onDismiss()
                          } catch (e) {
                            console.error(e)
                          }
                        }
                        onDismiss()
                      } catch (e) {
                        console.error(e)
                      }
                    }}
                  >
                    {isSaving ? (
                      <>
                        <Spinner size={4} />
                        <div className="px-2">Saving</div>
                      </>
                    ) : (
                      "Save"
                    )}
                  </button>
                </div>
                {objectiveNote && (
                  <button
                    type="button"
                    disabled={isDeleting || isSaving}
                    className="px-4 py-2 text-sm font-medium text-red-700 disabled:opacity-50 justify-self-start hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    onClick={async () => {
                      try {
                        await invokeDelete({ id: objectiveNote.id })
                        onDelete(objectiveNote.id)
                        onDismiss()
                      } catch (e) {
                        console.error(e)
                      }
                    }}
                  >
                    {isDeleting ? "Removing..." : "Remove"}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      )}
      {isError && (
        <ErrorRetry
          retry={() => {
            refetch().catch((_err) => {})
          }}
        />
      )}
    </>
  )
}

export default ObjectiveNoteEdit
