import { ClockIcon, XMarkIcon } from "@heroicons/react/24/outline"
import React, { useEffect, useState } from "react"
import HoverButton from "src/core/components/HoverButton"
import MenuButton, { MenuOption } from "src/core/components/MenuButton"
import { IObjectiveResult } from "src/objectives/objectives.interfaces"
import { FocusSessionDuration } from "../focus-session.constants"
import { useFocusSessionContext } from "../focus-session.context"

interface FocusSessionButtonProps {
  objective: IObjectiveResult
  className?: string
}

const FocusSessionButton: React.FC<FocusSessionButtonProps> = ({ objective, className }) => {
  const {
    setFocusDuration,
    focusedObjectiveId,
    setFocusedObjectiveId,
    startTimer,
    displayedTimeLeft,
    startFocus,
    stopFocus,
  } = useFocusSessionContext()

  useEffect(() => {
    if (objective.duration) {
      setFocusDuration(objective.duration)
    }
  }, [objective.duration, setFocusDuration])

  useEffect(() => {
    if (objective.id && objective.focusRemainingSec && objective.focusRemainingSec > 0) {
      setFocusedObjectiveId(objective.id)
      startTimer(objective.focusRemainingSec * 1000)
    }
  }, [objective.focusRemainingSec, objective.id, setFocusedObjectiveId, startTimer])

  const [menuOptions, setMenuOptions] = useState<MenuOption[]>([])

  useEffect(() => {
    setMenuOptions(
      Object.keys(FocusSessionDuration)
        .filter((duration) => !isNaN(Number(duration)))
        .map((duration) => {
          return {
            key: duration,
            value: `${duration} min`,
            action: async () => {
              await startFocus(objective.id, Number(duration))
            },
          }
        })
    )
  }, [objective.id, startFocus])

  return focusedObjectiveId === objective.id ? (
    <HoverButton
      hoverChildren={
        <>
          <XMarkIcon className="w-5 h-5 text-white stroke-current" /> Cancel
        </>
      }
      action={async () => {
        await stopFocus()
      }}
    >{`${displayedTimeLeft} min left`}</HoverButton>
  ) : (
    <MenuButton
      name="Timer"
      title="Focus"
      options={menuOptions}
      icon={<ClockIcon className="w-5 h-5 " aria-hidden="true" />}
      className={className}
    ></MenuButton>
  )
}

export default FocusSessionButton
