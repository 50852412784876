import { useSession } from "@blitzjs/auth"
import { HandThumbUpIcon, MoonIcon, SunIcon } from "@heroicons/react/24/outline"
import Image from "next/image"
import { useEffect, useRef } from "react"
import { useObjectivesContext } from "src/objectives/objectives.context"
import emptyBoxImage from "../../../public/empty-box.svg"
import { NestedSortable } from "../../core/components/Tree/NestedSortable"
import { ObjectiveStatus } from "../objectives.constants"
import ObjectiveAddItem from "./ObjectiveAddItem"
import ObjectivesListSkeleton from "./ObjectivesListSkeleton"

const ObjectivesList = () => {
  const {
    isViewOnly,
    objectives,
    searchInput,
    debouncedInput,
    isLoading,
    isCurrentUser,
    objectiveStatus,
    currentItems,
    onOrderChange,
  } = useObjectivesContext()

  const { userId } = useSession({ suspense: false })

  const canAdd = () => {
    return (
      userId &&
      !isLoading &&
      searchInput &&
      !!objectives.filter &&
      objectives.filter((objective) => {
        return objective["name"].toLowerCase() === debouncedInput.trim().toLowerCase()
      }).length <= 0
    )
  }

  const hasNoSearchResults = () => {
    return !isLoading && debouncedInput.length > 0 && !objectives.length && !!!userId
  }

  const hasNoAdoptedObjectives = () => {
    return !isLoading && !objectives.length && !!!searchInput
  }

  const isMountedRef = useRef(false)
  useEffect(() => {
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  }, [])

  return (
    <>
      {canAdd() && <ObjectiveAddItem name={debouncedInput} />}

      {isMountedRef.current && !isLoading && (
        <NestedSortable
          defaultItems={currentItems}
          collapsible
          onOrderChange={onOrderChange}
          indicator
          removable={!isViewOnly}
        />
      )}

      {hasNoSearchResults() && (
        <div className="flex items-center justify-center my-8 opacity-20">
          <Image src={emptyBoxImage} alt="" width="50" height="50" />
          <p className="px-4 text-xl font-bold">No results found.</p>
        </div>
      )}

      {hasNoAdoptedObjectives() &&
        !isViewOnly &&
        (isCurrentUser ? (
          <div className="flex items-center justify-center m-8 opacity-20 text-center">
            {objectiveStatus === ObjectiveStatus.ACTIVE && (
              <>
                <SunIcon className="w-16 h-16 " aria-hidden="true" />
                <p className="px-4 text-xl font-bold">
                  You have no active objectives. Time to explore!
                </p>
              </>
            )}
            {objectiveStatus === ObjectiveStatus.INACTIVE && (
              <>
                <MoonIcon className="w-16 h-16 " aria-hidden="true" />
                <p className="px-4 text-xl font-bold">You have no inactive objectives.</p>
              </>
            )}
            {objectiveStatus === ObjectiveStatus.SUGGESTED && (
              <>
                <HandThumbUpIcon className="w-16 h-16" aria-hidden="true" />
                <p className="px-4 text-xl font-bold">You have no suggested objectives.</p>
              </>
            )}
          </div>
        ) : (
          <div className="flex items-center justify-center my-8 opacity-20">
            <p className="px-4 text-xl font-bold">
              This person is not following any objectives...yet.
            </p>
          </div>
        ))}

      {isLoading && <ObjectivesListSkeleton />}
    </>
  )
}

export default ObjectivesList
