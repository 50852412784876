import { useEffect, useState } from "react"
import { formatDuration } from "../../../utils"
import { IObjectiveNote } from "../objective-notes.interface"

interface ObjectiveNoteProps {
  objectiveNote?: IObjectiveNote
  onClick?: () => void
  duration?: number
}

const ObjectiveNoteItem = ({ objectiveNote, onClick, duration }: ObjectiveNoteProps) => {
  const [displayDate, setDisplayDate] = useState<string>("Today")

  useEffect(() => {
    if (objectiveNote) {
      const today = new Date().toISOString().split("T")[0]
      if (objectiveNote.createdAt.toISOString().split("T")[0] === today) {
        setDisplayDate("Today")
      } else {
        setDisplayDate(
          objectiveNote.createdAt.toLocaleDateString("en-US", {
            weekday: "long",
            month: "short",
            day: "numeric",
          })
        )
      }
    }
  }, [objectiveNote])

  return (
    <div
      className="flex items-center space-x-4 p-4 border-b-2 hover:bg-gray-300 cursor-pointer"
      onClick={onClick}
    >
      <div className="flex flex-1 min-w-0 items-center justify-between">
        <p className="text-md font-medium text-gray-900">{displayDate}</p>
        {duration && (
          <p className="inline-flex flex-shrink text-gray-500">{formatDuration(duration)}</p>
        )}
      </div>
      {onClick && (
        <div className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50">
          {objectiveNote && objectiveNote?.id > 0 ? "Edit" : "Add"}
        </div>
      )}
    </div>
  )
}

export default ObjectiveNoteItem
