import { useQuery } from "@blitzjs/rpc"
import { Objective } from "@prisma/client"
import { useEffect, useState } from "react"
import ErrorRetry from "src/core/components/ErrorRetry"
import Modal from "src/core/components/Modal"
import Spinner from "src/core/components/Spinner"
import getDailyFocusDurationsByObjectiveId from "../../focus-session/queries/getDailyFocusDurationsByObjectiveId"
import { IObjectiveNote } from "../objective-notes.interface"
import getObjectiveNotes from "../queries/getObjectiveNotes"
import ObjectiveNoteEdit from "./ObjectiveNoteEdit"
import ObjectiveNoteItem from "./ObjectiveNoteItem"

interface ObjectiveNotesProps {
  objective: Objective
}

const ObjectiveNotes = ({ objective }: ObjectiveNotesProps) => {
  const [result, { isSuccess, isError, isLoading, refetch }] = useQuery(
    getObjectiveNotes,
    {
      where: {
        objectiveId: objective.id,
      },
      select: {
        id: true,
        createdAt: true,
        updatedAt: true,
      },
    },
    {
      suspense: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  )

  const [dailyFocusDurations, {}] = useQuery(getDailyFocusDurationsByObjectiveId, {
    objectiveId: objective.id,
  })

  const [objectiveNotes, setObjectiveNotes] = useState<IObjectiveNote[]>([])
  const [objectiveNote, setObjectiveNote] = useState<IObjectiveNote>()
  const [isEditting, setIsEditting] = useState(false)

  useEffect(() => {
    // Add dummy objective notes for focus durations if they don't exist yet
    let newObjectiveNotes = result ? result.objectiveNotes : []

    if (dailyFocusDurations.length > 0) {
      newObjectiveNotes = newObjectiveNotes.map((note) => {
        const noteDate = note.createdAt.toISOString().split("T")[0]
        const focusDuration = dailyFocusDurations.find((duration) => {
          return duration.date === noteDate
        })?.duration

        if (focusDuration) {
          return {
            ...note,
            duration: focusDuration,
          }
        }
        return note
      })

      dailyFocusDurations
        .filter(({ date }) => {
          // Not in newObjectiveNotes
          return !newObjectiveNotes.some((note) => {
            return note.createdAt.toISOString().split("T")[0] === date
          })
        })
        .forEach(({ duration, date }, idx) => {
          newObjectiveNotes.push({
            id: -idx,
            createdAt: new Date(date),
            updatedAt: new Date(date),
            duration,
          } as any)
        })

      const today = new Date().toISOString().split("T")[0]
      if (!newObjectiveNotes.some((note) => note.createdAt.toISOString().split("T")[0] === today)) {
        newObjectiveNotes.push({
          id: -dailyFocusDurations.length,
          createdAt: new Date(),
          updatedAt: new Date(),
        } as any)
      }
    } else {
      const today = new Date().toISOString().split("T")[0]
      if (!newObjectiveNotes.some((note) => note.createdAt.toISOString().split("T")[0] === today)) {
        newObjectiveNotes.push({
          id: -1,
          createdAt: new Date(),
          updatedAt: new Date(),
        } as any)
      }
    }
    setObjectiveNotes(newObjectiveNotes)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dailyFocusDurations, result])

  const onSave = ({ id, createdAt, updatedAt }) => {
    const newNoteDate = createdAt.toISOString().split("T")[0]
    setObjectiveNotes((prev) => {
      const index = prev.findIndex((note) => {
        // Find by date
        return note.createdAt.toISOString().split("T")[0] === createdAt.toISOString().split("T")[0]
      })
      if (index === -1) {
        return [
          {
            id,
            createdAt,
            updatedAt,
          },
          ...prev,
        ]
      } else {
        return prev.map((note) => {
          const noteDate = note.createdAt.toISOString().split("T")[0]

          return noteDate === newNoteDate
            ? { id, createdAt, updatedAt, duration: note.duration }
            : note
        })
      }
    })
  }

  const onDelete = (id) => {
    const today = new Date().toISOString().split("T")[0]
    setObjectiveNotes((prev) =>
      prev
        .filter(
          (note) =>
            note.createdAt.toISOString().split("T")[0] === today ||
            note.id !== id ||
            !!note.duration
        )
        .map((note) => {
          if (note.id === id) {
            return {
              ...note,
              id: -note.id,
            }
          }
          return note
        })
    )
  }

  return (
    <>
      {isError && (
        <ErrorRetry
          retry={() => {
            refetch().catch((_err) => {})
          }}
        />
      )}
      {isLoading && (
        <div className="flex justify-center w-full mt-4">
          <Spinner size={16} />
        </div>
      )}
      {isSuccess && (
        <div className="flow-root">
          <ul>
            {objectiveNotes
              .sort((a, b) => {
                return a.createdAt > b.createdAt ? -1 : 1
              })
              .map((note) => (
                <li key={note.id}>
                  <ObjectiveNoteItem
                    objectiveNote={note}
                    onClick={() => {
                      setObjectiveNote({
                        id: note.id,
                        createdAt: note.createdAt,
                        updatedAt: note.updatedAt,
                      })
                      setIsEditting(true)
                    }}
                    duration={note.duration}
                  />
                </li>
              ))}
          </ul>
        </div>
      )}
      {/* <div className="mt-6">
        <a
          href="#"
          className="flex items-center justify-center w-full px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50"
        >
          View all
        </a>
      </div> */}

      <Modal isOpen={isEditting} setIsOpen={setIsEditting}>
        <ObjectiveNoteEdit
          objectiveNoteId={objectiveNote?.id}
          objectiveId={objective.id}
          onDismiss={() => {
            setIsEditting(false)
          }}
          onSave={onSave}
          onDelete={onDelete}
          date={objectiveNote?.createdAt}
        />
      </Modal>
    </>
  )
}

export default ObjectiveNotes
