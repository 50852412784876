import { Popover } from "@headlessui/react"
import { HandThumbUpIcon } from "@heroicons/react/24/outline"
import React from "react"
import ObjectiveUserVotersPopover from "./ObjectiveUserVotersPopover"

interface Props {
  objectiveId: number
  voteCount: number
}

const ObjectiveUserVotersButton = ({ objectiveId, voteCount }: Props) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
      }}
    >
      <Popover className="relative">
        {({ open }) => (
          <>
            <Popover.Button className="inline-flex items-center px-3 py-2 text-base font-medium rounded-md hover:bg-gray-300 group hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
              <div className="inline-flex items-center">
                <HandThumbUpIcon className="w-5 h-5 text-grey-100" />
                <div className="pl-1">{voteCount}</div>
              </div>
            </Popover.Button>
            {open && <ObjectiveUserVotersPopover objectiveId={objectiveId} voteCount={voteCount} />}
          </>
        )}
      </Popover>
    </div>
  )
}

export default ObjectiveUserVotersButton
